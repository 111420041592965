.pagebase {
    .pagebase_container {
        background-color: #F1F2F2;
        background-position-y: 20px;
        background-position-x: 100%;
        overflow-y: auto;
        position: absolute;
        display: flex;
        top: 0;
        right: 0;
        width: calc(100% - 300px);
        height: 100vh;
        border-radius: 0 0 0 5rem;
        padding-top: 5rem;
        margin-bottom: 150px;
    }
}

.sub_academy_collections {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: -70px;
  border-radius: 20px;
  box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.20);

  .sub_academy:last-child {
    border-radius: 0 0 0.5rem 0.5rem;
  }

  ul {
    width: 100%;
  }

  li {
    list-style-type: none;
  }

  .sub_academy {
    list-style-type: none;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid #25304515;
    border-top: 1px solid #25304515;
    display: grid;
    grid-template-columns: 1fr auto auto auto auto auto auto;
    gap: 1rem;
    align-items: center;
    justify-items: flex-start;

    gap: 1rem;
    background-color: $white;
    transition: all 250ms ease-in-out;
    padding: 0 1rem;

    .lesson_heading {
      cursor: pointer;

      h4 {
		padding: 0 10px;
		border-radius: 100px;
        max-width: 150px;
        width: 100%;
        white-space: nowrap;
      }
    }

    label {
      background-color: #6BCAD24D;
      padding: 0.25rem 1rem;
      border-radius: 100px;
    }

    .files_button {
      border-radius: 100px;
      border: none;
      width: 110px;
      text-align: center;
      height: 40px;
      background-color: $soft-teal;
      color: $white;
      letter-spacing: 0.5px;
      transition: all 250ms ease-in-out;
    }

    .files_button:hover {
      border: 1px solid $soft-teal;
      background-color: #fff;
      color: $admiral-blue;
    }

    .button_wrapper {
      display: flex;
      gap: 1rem;

      button {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: #21273D10;
        border-radius: 6px;
      }
    }
  }

  .sub_academy:nth-child(1) {
    border-top: none;
    border-radius: 0.5rem 0.5rem 0 0;
    box-shadow: 0px -20px 10px -8px #00000070;
  }

  .sub_academy:last-child:nth-child(1) {
    border-radius: 0.5rem;
  }
}

@media only screen and (max-width: 1280px) {
	.managers_drop_down {
		display: none
	}
}

@media only screen and (max-width: 1080px) {
	.academy_item_status_drop_down {
		display: none
	}
}
@import "./components/NewUserModal/NewUserModal";

.home_user {
    position: relative;
	max-width: 1350px;
	width: 100%;
	margin: 0 auto;
	padding: 0 30px 150px 30px;
	

    ul {
        list-style-type: none;
    }

    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .field_name {
        display: grid;
        width: 100%;
        grid-template-columns: 250px auto 200px 170px;
        margin-top: 54px;
        margin-bottom: 0.5rem;
        padding-left: 30px;
        gap: 1rem;
        li {
            text-transform: uppercase;
            opacity: 0.7;
            font-size: 12px;
        }   
        &__role {
            width: 200px;
        }
        &__password {
            width: 180px;
        }
    }

    .user {
        font-size: 14px;
        & > li {
            width: 100%;
            height: 70px;
            display: grid;
            grid-template-columns: 250px auto 200px 170px;
            border-radius: 0.5rem;
            background-color: $white;
            gap: 1rem;
            align-items: center;
            padding: 0 15px 0 30px;
            margin-top: 1rem;
        }
        .role_drop_down {
            select {
                background-color: #21273D06;
                height: 40px;
                width: 200px;
                padding: 0 0.5rem;
                border: none;
                border-radius: 0.5rem;
            }
        }
        input {
            width: 180px;
            height: 40px;
            background-color: #21273D06;
            border: none;
            padding: 0 1rem;
            border-radius: 0.5rem;
        }
        .update {
            background-color: $soft-teal;
            border: none;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            text-transform: uppercase;
            height: 40px;
            width: 110px;
            span {
                margin-right: 0.5rem;
            }
        }

        .button_wrapper {
            display: flex;
            width: 150px;
            justify-content: space-between;
            button {
                border-radius: 6px;
                border: none;
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .new_user {
        margin: 2rem 0;
        width: 1100px;
        display: flex;
        justify-content: flex-end;
    }
}
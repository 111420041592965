.curriculum__home__section_dnd_container {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding-bottom: 60px;
    .container_section {
        padding: 1rem;
        background-color: #21273D10;
        border: 1px dashed #21273D30;
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        margin-bottom: 100px;
        .modules_list {
			min-height: 50px;
            display: flex;
            flex-direction: column;
            width: calc(100% - 50px);
            li {
                text-transform: capitalize;
            }
        }
        &__header {
            width: 100%;
            h3 {
                text-transform: uppercase;
            }
        }
        .button_wrapper {
            display: flex;
            gap: 2rem;
            position: absolute;
            bottom: -100px;
            left: 0;
            button {
                height: 40px;
                border-radius: 100px;
                border: none;
                background-color: $soft-teal;
                color: $white;
            }
            .module_button {
                width: 130px;
            }
            .webinar_button {
                width: 120px;
            }
        }
    }
	.curriculum-no-section-modules {
		font-size: 14px;
		margin-top: 7px;
		color: grey;
		svg {
			width: 20px;
			color: #999999;
			top: 7px;
			left: -5px;
			position: relative;
		}
	}
}
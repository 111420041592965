.home_settings {
    width: 100%;
    position: relative;
    &__settings_container {
        width: 100%;
		max-width: 1350px;
		padding: 0 30px 30px 30px;
		margin: 0 auto;
    }
    .change_password_form {
        .name_container {
            display: flex;
        }
        .name {
            font-size: 30px;
            line-height: 35px;
            font-weight: 500;
            display: inline-block;
            width: 100%;
            margin-bottom: 1rem;
        }
        button {
            width: 220px;
            height: 60px;
            background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
            color: $white;
            text-transform: capitalize;
            border: none;
            border-radius: 100px;
            margin-top: 2rem;
        }

        .update {
            width: 100%;
            height: 10rem;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
}


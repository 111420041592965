@import './SectionListItem/SectionListItem';
@import './CurriculumSection/CurriculumSection';

.mirror_section {
    width: 980px;
    border: 1px solid #21273D20 !important;
}
.draggable-container--is-dragging {
    cursor: move;
}

.dragged_over {
    background-color: #21273D30  !important;
    border: 1px dashed #21273D20;
}
.dragged_over > * {
    opacity: 0;
    transition: all 150ms ease-in-out;
    transform: scale(0.5);
}
.mirror_list_item {
    width: 880px !important;
}


@import "./components/Header/Header";
@import "./components/AcademyItem/AcademyItem";
@import "./components/ManagersDropDown/ManagersDropDown";

.academy_collections_container > .academy_collection {
    position: relative;
    margin-top: 2rem;
    .add_academy {
        height: 54px;
        width: 160px;
        border: 1px solid $soft-teal;
        border-radius: 100px;
        transition: all 250ms ease-in-out;
    }
    .add_academy:hover {
        background-color: $soft-teal;
        color: #fff;
    }
}

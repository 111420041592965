.TextInput {
    input {
        display: inline;
        width: 100%;
        height: 50px;
        background-color: #F1F2F2;
        border-radius: 0.5rem;
        border: transparent 2px solid;
        padding: 0 1rem;
    }
    label {
        font-size: 13px;
        line-height: 35px;
        opacity: 0.7;
        font-weight: 500;
        width: 100%;
        margin: 1rem 0 0.5rem;
        display: inline-block;
    }
    .error-message {
        color: #f30000;
        font-size: 15px;
        box-sizing: border-box;
        max-height: 0px;
        transition: max-height 0.15s ease-out;
        p {
            padding: 10px;
        }
    }
	.TextInputAppendIcon {
		position: relative;
		display: inline-block;
		
	}
}
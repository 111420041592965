.drop_down {
    width: 100%;
    height: 50px;
    border-radius: 6px;
    position: relative;
    .current {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0 1rem;
        background-color: #21273D10;
        cursor: pointer;
        border-radius: 6px;
    }

    .options {
        background-color: $white;
        position: absolute;
        top: 0px;
        left: 0;
        width: 100%;
        padding: 1rem 0.5rem;
        top: -100vh;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border: 1px solid $soft-teal;
        border-radius: 6px;
        z-index: 100;
        max-height: 300px;
        overflow-y: scroll;
        li {
            min-height: 40px;
            display: flex;
            align-items: center;
            padding: 0 0.5rem;
            border-radius: 6px;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .done_icon {
                font-size: 1rem;
                color: $soft_teal;
            }

        }
        li:hover {
            background-color: $soft-teal;
            color: $white;

            .done_icon {
                color: $white;
            }
        }
    }
	.error-message {
        color: #f30000;
        font-size: 15px;
        box-sizing: border-box;
        max-height: 0px;
        transition: max-height 0.15s ease-out;
        p {
            padding: 10px;
        }
    }
}
.add_academy_collection {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 55px 80px;
    z-index: 10;
    border-radius: 8px;
    padding-bottom: 50px;
    transition: all 250ms ease-in-out;
    overflow: hidden;
    span {
        display: block;
    }
    h4 {
        margin-top: 2rem;
        opacity: 0.7;
    }

    label {
        font-size: 13px;
        font-weight: 500;
        opacity: 0.8;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        display: inline-block;
    }

    .close_btn {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: transparent;
        border: none;
    }

    input {
        width: 100%;
        border: none;
        height: 50px;
        background-color: #F1F2F2;
        margin-bottom: 1rem;
        border-radius: 8px;
        padding: 0 1rem;
        outline: none;
    }
    &__footer {
        position: absolute;
        left: 0;
        right: 0;
        height: 120px;
        bottom: 0;
        background-color: #F1F2F2;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;

        .button_wrapper {
            width: 80%;
            padding: 2px;
            border-radius: 100px;
            .submit_button {
                background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
                margin: 0 auto;
                height: 60px;
                border-radius: 100px;
                border: none;
                color: $white;
                width: 100%;
                transition: all 250ms ease-in-out;
            }
        }

        .button_wrapper:hover {
            background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
            .submit_button {
                background: #F1F2F2;
                color: $admiral-blue;
            }
        }

    }
}

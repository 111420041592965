@keyframes flashing {
    0% {
        opacity: 100%;
    }
    50% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

.new_module {
	background-size: auto;
	background-position-y: 30px;
	background-position-x: 30px;
	background-attachment: fixed;
	background-color: #f1f2f2;
    height: 100%;
    width: calc(100% - 300px);
    position: absolute;
    right: 0;
    border-radius: 0 0 0 5rem;
    overflow-y: scroll;
    padding-bottom: 260px;
}
.curriculum__new_module {
    height: 100%;
    padding: 5rem;
    margin: 0 auto;
    .primary_button_wrapper {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }

    &__form {
		position: relative;
        background-color: $white;
        padding: 48px 80px;
        display: flex;
        flex-direction: column;
        border-radius: 6px;
        margin-top: 40px;
        .info_wrapper {
            margin-bottom: 43px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            .title, .subtitle {
                position: relative;
                img {
                    margin-left: 1rem;
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
            .title {
                input::placeholder {
                    color: #21273D;
                }
            }
        }
        input {
            height: 60px;
            padding: 0 1rem;
            background-color: transparent;
            border: none;
            border-bottom: 1px dashed #21273D20;
            border-radius: 6px;
            margin-bottom: 20px;
            outline: none;
        }
		&__top_container {
			flex-wrap: wrap;
			justify-content: space-between;
			display: flex;
			> div {
				display: flex;
				justify-content: flex-start;
			}
			> div:nth-child(2) {
				justify-content: flex-end;
			}
		}
    }
}

.curriculum_status_text {
	margin-top: 53px;
	color: grey
}

.curriculum_stage_change_toggle {
	position: relative;
	width: 100%;
	height: 120px;
}

.curriculum_stage_change_toggle_container {
	position: absolute;
	right: 0px;
	top: 35px
}

.curriculum_stage_change_toggle > #input-checkbox{
	width: 200px;
	position: relative;
	right: -10px
}

.curriculum_status {
	position: absolute;
	top: 55px;
	margin-top: 30px;
	border-radius: 10px;
	right: 80px;
	.current {
		background-color: #fff !important;
	}
}

@media only screen and (max-width: 1280px) {
	.curriculum_status {
		position: static;
	}
}
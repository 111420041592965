.add_academy_form {
    width: 540px;
    background-color: $white;
    border-radius: 12px;
    z-index: 100;
    position: absolute;
    top: 50%;
    left: 50%;
	margin: -300px 0 0 -270px;
    overflow: hidden;

    .current {
        min-height: 50px !important;
    }

    .content {
        padding:  3rem 5rem;
    }
    .close_panel {
        position: absolute;
        top: 1rem;
        right: 1rem;
        background-color: transparent;
        border: none;
    }

    header {
        margin-bottom: 2rem;
    }

    label {
        font-size: 13px;
        font-weight: 500;
        opacity: 0.8;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        display: inline-block;
    }

    input {
        display: inline-block;
        width: 100%;
        background-color: #F1F2F2;
        border: none;
        height: 50px;
        border-radius: 8px;
        padding: 0 1rem;
        outline: none;
    }


    .drop_down {
        min-height: 50px;
        height: auto !important;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #F1F2F2;
        border-radius: 8px;
    }

	.drop_down_manager {
        min-height: 50px;
        height: auto !important;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #F1F2F2;
        border-radius: 8px;
    }

    .master_wrapper {
        display: flex;
        align-items: center;
        .master_checkbox {
            width: 24px;
            height: 24px;
            border-radius: 5px;
            border: 1px solid $soft-teal;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            margin-right: 1rem;
        }
    }
	
    .tags_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        margin: 2rem 0;

        &.minor {
            margin: 0;
            width: 100%;
            padding: 8px 10px;
        }
        .tag {
            border: 1px solid #25304533;
            padding: 0.5rem 1rem;
            border-radius: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 1rem;
            cursor: pointer;
            transition: 0.1s ease-in;

            &:hover {
                opacity: 0.6;
            }
            span {
                margin-right: 1rem;
            }
        }
    }

    .certificate {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0;

        &.small {
        }

        span {
            text-transform: uppercase;
            opacity: 0.7;
        }
    }

    .submit_button {
        height: 120px;
        width: 100%;
        background-color: #F1F2F2;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
        .button_wrapper {
            margin: auto 0;
            background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
            padding: 2px;
            border-radius: 100px;
            transition: all 250ms ease-in-out;
            width: 70%;
            button {
                width: 100%;
                height: 60px;
                background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
                border-radius: 100px;
                border: none;
                color: $white;
                transition: background 250ms ease-in-out;
            }
        }
    
        .button_wrapper:hover {
            background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
            button {
                background: #f1f2f2;
                color: $admiral-blue;
            }
        }
    }

	.error-message {
        color: #f30000;
        font-size: 15px;
        box-sizing: border-box;
        max-height: 0px;
        transition: max-height 0.15s ease-out;
    }
    
}

.curriculum__home__section_dnd_container > .container_section {
    list-style-type: none;
    .container_section__list_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        padding: 0 1rem;
		margin: 8px 0;
        height: 70px;
        border-radius: 8px;
        width: 100%;
    }
    .container_section__list_item > .left_wrapper {
        display: flex;
        align-items: center;
        gap: 1rem;
		flex-basis: 400px;

        .drag_button {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20px;
            width: 20px;
            background-color: transparent;
            border: none;
        }
        .text_wrapper {
            display: flex;
            align-items: center;
            border-bottom: 1px dashed #21273D20;
            gap: 0.5rem;
        }
    }

    .container_section__list_item > .center_wrapper {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        input {
            height: 40px;
            width: 70px;
            background-color: #21273D20;
            border-radius: 6px;
            border: none;
            text-align: center;
            outline: none;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }
    }

    .container_section__list_item > .right_wrapper {
		svg {
			margin: 8px;
			color: grey;
		}
        display: flex;
		flex-basis: 400px;
		justify-content: flex-end;
        gap: 1rem;
        button {
            background-color: #21273D20;
            width: 40px;
            height: 40px;
            border-radius: 6px;
            border: none;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}
@media only screen and (max-width: 1080px) {
	.container_section__list_item_release_in {
		display: none !important
	}
}

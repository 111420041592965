// linear - gradient colors login
$port-gore-blue: #262261;
$downy: #6BCAD2;

// linear gradient colors -- login hero
$jacarta-blue: #272563;

// linear gradient colors - sidebar
$admiral-blue: #21273c;
$soft-teal: #6bcad2;

// font colors or lines
$admiral-blue: #21273c;
$white: #fff;

// background color 
$background-color: #F1F2F2;
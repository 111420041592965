.login {
    height: 100vh;
    display: grid;
    grid-template-columns: 50% 50%;
    &__container_left {
        position: relative;
        border-radius: 0 0 5rem 0;
        overflow: hidden;
        img {
            object-fit: cover;
            height: 100%;
            width: 100%;
        }
        .terms_and_conditions {
            position: absolute;
            bottom: 4rem;
            left: 4rem;
            color: #fff !important;
            cursor: pointer;
        }
        .overlay {
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(360deg, #262261, #26226100);
            mix-blend-mode: multiply;
            opacity: 0.5;
        }
    }

    &__container_right {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        padding: 2rem;
        width: 100%;
        > div {
            position: relative;
            flex-grow: 1;
            max-width: 400px;
        }
        h1 {
            text-align: center;
            color:  $admiral-blue;
            margin-bottom: 1rem;
        }
        .login_error {
            color: red;
            align-content: center;
            text-align: center;
        }
        .email, .password {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 1rem;
            input {
                height: 50px;
                border-radius: 0.5rem;
                border: none;
                outline: none;
                padding: 0 1rem;
            }
            label {
                text-transform: uppercase;
                font-size: 14px;
                line-height: 35px;
                opacity: 0.7;
                color: $admiral-blue;
            }
        }

        
        
        .button_wrapper {
            padding: 1.5px;
            border-radius: 100px;
            margin: 2rem 0;
            button {
                background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
                height: 64px;
                width: 100%;
                color: $white;
                font-size: 18px;
                font-weight: 500;
                border-radius: 100px;
                border: none;
                transition: all 150ms ease-in-out;
            }
        }

        .button_wrapper:hover {
            background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);

            button {
                background: #f1f2f2;
                color: $admiral-blue;
            }
        }
        
        span {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
        }
    }
}

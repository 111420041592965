@import "./components/AcademyCollection/AcademyCollection";

.home_dashboard {
    width: 100%;
    header > h1 {
        font-size: 3.25rem;
        margin-bottom: 2rem;
    }
    &__dashboard_container {
		max-width: 1350px;
        width: 100%;
		margin: 0 auto;
		height: fit-content;
		padding: 0 30px 100px 30px;
		margin-bottom: 100px;
		h1 {
			display: inline-block;
			margin-right: 50px;
		}
    }

    &__dashboard_container__footer {
        background-color: $background-color;
        position: fixed;
        right: 0;
        bottom: 0;
        border-top: 1px solid #21273D10;
        padding-right: 80px;
        height: 120px;
        z-index: 10;
        display: flex;
        justify-content: flex-end;
        width: calc(100% - 300px);
        border-radius: 0 0 0 5rem;
        .button_wrapper {
            margin: auto 0;
            background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
            padding: 2px;
            border-radius: 100px;
            transition: all 250ms ease-in-out;
            button {
                min-width: 240px;
                height: 60px;
                background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
                border-radius: 100px;
                border: none;
                color: $white;
                transition: background 250ms ease-in-out;
            }
        }

        .button_wrapper:hover {
            background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
            button {
                background: #f1f2f2;
                color: $admiral-blue;
            }
        }
    }
}

.home__sidebar {
	width: 100%;
    height: 100vh;
    background: linear-gradient(0deg,  #6BCAD2 -150%, #21273D 80% );
    position: absolute;
    left: 0;
    nav {
        list-style-type: none;
        color: $white;
        margin-top: 15rem;
        width: 20rem;
    }
    .logo {
        position: absolute;
        z-index: 1;
        top: 50px;
        left: 50px;
    }
    .active {
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.10), rgba(255, 255, 255, 0.001));
        .icon_wrapper {
            img {
                filter: invert(20%) sepia(57%) saturate(355%) hue-rotate(137deg) brightness(100%) contrast(100%);
            }
        }
        opacity: 1;
        transition: opacity 250ms ease-in-out;
    }

    nav > li {
        display: flex;
        align-items: center;
        padding: 0 55px;
        height: 56px;
        cursor: pointer;
        opacity: 0.5;
        .icon_wrapper {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

        }
    }
}
.managers_drop_down {
    width: 200px;
    position: relative;
    height: 40px;
    .current_wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        background-color: #21273D10;
        height: 40px;
        padding: 0 1rem;
        align-items: center;
        cursor: pointer;
        border-radius: 6px;
        .active_managers {
            display: flex;
            width: 100%;
            gap: 0.25rem;
            .active_manager {
                width: 30px;
                height: 30px;
                border-radius: 100%;
                border: 1px solid $soft-teal;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                letter-spacing: 1px;
            }
        }
    }

    .managers_toggle {
        background-color: #fff;
        display: flex;
        padding: 0 0.5rem;
        position: absolute;
        left: 0;
        right: 0;
        flex-direction: column;
        border-radius: 6px;
        top: -100vh;
        border: 1px solid $soft-teal;
        transition: opacity 250ms ease;
        z-index: 10;
        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 40px;
            cursor: pointer;
            padding: 0 0.5rem;
        }

        .managers_list {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding-bottom: 1rem;
            li {
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 6px;
                padding: 0 0.5rem;
                cursor: pointer;
                span {
                    font-size: 14px;
                }
            }
            li:hover {
                background-color: $soft-teal;
                color: $white;
                .done_icon {
                    color: $white !important;
                }
            }
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');


body {
    font-family: 'DM Sans', sans-serif;
    color: $admiral-blue;
    font-weight: 400;
    h1, h2, h3, h4, h5, h6, .text_20,.text_30, .text_28 {
        font-weight: 500;
    }
    h1 {
        font-size: 2.5rem; // 40px
    }

    h2 {
        font-size: 1.125rem; // 18px
    }
    h3 {
        font-size: 1rem; // 16px
    }
    h4 {
        font-size: 0.875rem; // 14px
    }

    h5 {
        font-size: 0.8125rem; // 13px
    }
    h6 {
        font-size: 0.75rem; // 12px
    }
    .terms_and_condtions-text {
        font-size: 0.9375rem;
        line-height: 7.75rem;
        color: $white;
    }

    .text_28 {
        font-size: 1.75rem;
    }

    .text_20 {
        font-size: 1.25rem;
    }

    .text_30 {
        font-size: 1.875rem;
    }

    .text_14{
        font-size: 0.875rem; // 14px
        font-weight: 400;
		white-space: nowrap;
    }
    input {
        font-size: 1rem;
        color: $admiral-blue;
    }
}
.text_editor {
    background-color: #21273D06;
    border: none;
    border-radius: 6px;
    height: 200px;
    position: relative;
    &__editor {
        height: 150px;
        top: 0;
        padding: 1rem;
        .DraftEditor-root, .DraftEditor-root > .DraftEditor-editorContainer, .DraftEditor-root > .DraftEditor-editorContainer > .public-DraftEditor-content{
            height: 100%;
        }
        .DraftEditor-root  {
            overflow-y: auto;
        }
    }
    &__button_wrapper {
        bottom: 0;
        height: 50px;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        border-top: 1px solid #21273D10;
        gap: 0.5rem;
        button {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: transparent;
        }

    }
    &__button_wrapper, &__editor {
        width: 100%;
        position: absolute;
    }
}
.main_user__new_user {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 5rem;
    width: 540px;
    border-radius: 0.5rem;
    z-index: 150;
    overflow: hidden;
    header {
        .close_btn {
            position: absolute;
            top: 2rem;
            right: 2rem;
            border: none;
            background-color: transparent;
            color: $admiral-blue;
        }
    }
    header > span {
        font-size: 30px;
        line-height: 35px;
        font-weight: 500;

    }
    label {
        font-size: 13px;
        font-weight: 500;
        opacity: 0.8;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        display: inline-block;
    }
    input {
        display: inline-block;
        width: 100%;
        background-color: #F1F2F2;
        border: none;
        height: 50px;
        border-radius: 8px;
        padding: 0 1rem;
        outline: none;
    }
    .role_drop_down {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 0.5rem;
        select {
            background-color: #F1F2F2;
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 8px;
            padding: 0 1rem;
            outline: none;
        }
    }

    .submit_wrapper {
        background-color: #F1F2F2;
        width: 100%;
        position: absolute;
        height: 120px;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            width: 60%;
            height: 60px;
            background: linear-gradient(90deg, #262261 0%, #6BCAD2 100%);
            border-radius: 100px;
            border: none;
            color: $white;
        }
    }
}

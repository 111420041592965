.academy_collections_container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 20px;
        background:rgba(0,0,0,0.1);
        padding: 20px;
    }

    .academy_collection {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-direction: column;
        gap:20px;
        width: 100%;

        .header_card {
            display: flex;
            padding: 1rem;
            width: 100%;
            border-radius: 20px;
            min-height: 110px;
            align-items: flex-start;
            background-color: $admiral-blue;

            &__wrapper {
                .icon_wrapper {
                    margin-left: 0.25rem;
                    border: none;
                    background-color: transparent;
                }
            }
            .delete_academy{
                margin-left: auto;
                padding: auto;
                size: auto;
            }
            .delete_academy_button {
                background-color: transparent;
                border: none;
                transition: all 250ms ease-in-out;
                img {
                    filter: invert(100%) brightness(1000%);
                }
            }

        }

    }
}
.home_dashboard__dashboard_container > .academy_collections_container > .academy_collection {
    .header_card > .header_card_wrapper {
        height: 35px;
        display: flex;
        align-items: center;
        border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
        .icon_wrapper {
            margin-left: 5px;
        }
    }

}

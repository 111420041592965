.curriculum__sidebar {
    width: 100%;
    max-height: 100vh;
    overflow-y: scroll;
    background: linear-gradient(0deg,  #6BCAD2 -150%, #21273D 80% );
    min-height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    color: $white;
    padding-top: 10rem;
    padding-bottom: 10rem;
    .logo {
        position: absolute;
		top: 50px;
        left: 50px;
    }
    button {
        background-color: transparent;
        color: $white;
        border: none;
        display: flex;
        align-items: center;
        padding: 0 45px;
    }

    &__header {
        display: flex;
        padding: 0 45px;
        align-items: center;
        margin-top: 2rem;
        height: 56px;
        img {
            margin-right: 1rem;
        }
    }

    &__section {
        padding: 0 45px;
        margin-top: 2rem;
        width: 65%;
        &__header {
            margin: 13px 0;
            text-transform: uppercase;
        }
        &__container {
            border-left: 1px solid #6AC7D0;
            padding-left: 20px;
            display: flex;
            flex-direction: column;
            span {
                text-transform: capitalize;
                margin-bottom: 1.25rem;
            }
        }
    }
    &__section:nth-child(3) {
        margin-top: 0;
    } 

	&__contentplaceholder {
		height: 100%;
		width: calc(100% - 300px);
		position: fixed;
		top: 0;
		right: 0;
		box-sizing: border-box;
		border-radius: 0 0 0 5rem;
		padding-bottom: 260px;
	}
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
// common
@import './styles/ColorVariables';
@import './styles/Typography';

// login & main & curriculum & webinars
@import './routes/Login/Login';
@import './pages/Curriculum/Curriculum';


@import './components/NavSidebar/NavSidebar';
@import './components/Select/Select';
@import './pages/Dashboard/Dashboard';
@import './pages/Users/Users';
@import './pages/Settings/Settings';
@import './pages/Webhook/Webhook';

// components
@import './components/PageBase/PageBase';
@import './components/PageBase/Footer/Footer';
@import './components/form/textInput/textInput';
@import './components/CertificateBackgroundButton/CertificateBackgroundButton';

// features
@import './features/AddAcademyModal/AddAcademyModal';
@import './features/AddAcademyCollectionModal/AddAcademyCollectionModal';

body {
    background-color: $background-color;
    color: $admiral-blue;
    label {
        font-size: 13px;
        font-weight: 500;
        opacity: 0.8;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        display: inline-block;
    }
    img {
        display: block;
    }
    button {
        cursor: pointer;
    }

    input:focus::placeholder {
        opacity: 0;
    }
}
.pagebase_footerspacer {
  width: 100%;
  height: 150px;
}

.pagebase_footer {
  background-color: $background-color;
  position: fixed;
  right: 0;
  bottom: 0;
  border-top: 1px solid #21273D10;
  padding-right: 80px;
  height: 120px;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 300px);
  border-radius: 0 0 0 5rem;
}
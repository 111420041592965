#fileInput {
    display: none !important;
}

.file_upload_button {
    width: 25px;
    height: 25px;
    background: #F1F2F2;
    border-radius: 4px;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;
    transition: 0.1s ease-in;
    line-height: 100%;
    cursor: pointer;

    &:hover {
        background: #E5E5E5;
    }
}
.file_upload_text {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.files_button {
    border-radius: 100px;
    border: none;
    width: 110px;
    text-align: center;
    height: 40px;
    background-color: $soft-teal;
    color: $white;
    letter-spacing: 0.5px;
    transition: all 250ms ease-in-out;
    cursor: pointer;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}   
.files_button:hover {
    border: 1px solid $soft-teal;
    background-color: #fff;
    color: $admiral-blue;
}

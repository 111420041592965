.webhook {
    width: 100%;
    position: relative;
    &__webhook_container {
        width: 100%;
		max-width: 1350px;
		padding: 0 30px 30px 30px;
		margin: 0 auto;
		h1 {
			display: inline-block;
			margin-right: 50px;
		}
    }
}